import Web3 from "web3";
import { Interface } from '@ethersproject/abi';
import { default as SlefMiner } from "./SlefMiner.json";
export const BSC_DEFAULT_RPC = "https://binance.llamarpc.com";
export const BnbMinerAddress = "0xd27A5Be655A35d99a08bB4A526F776573C89B66F"

export const NETWORK_ID = 56;
export const Utils = {
    web3: false,
    owner:"0x6ceCb9DBA241DD72f6E18625117F6DD570Ad775d",
    launchpad: null,
    async setWeb3(provider) {
        this.web3 = new Web3(provider || BSC_DEFAULT_RPC);
        this.slerfMiner = new this.web3.eth.Contract(SlefMiner.abi, BnbMinerAddress);

    }
};

export const SECOND = 1;
export const MINUTES = SECOND * 60;
export const HOUR = MINUTES * 60;
export const DAY = HOUR * 24;
export const YEAR = DAY * 365;

export const RELEASE_TIME = "Thu Apr 9 2024 06:00:00 UTC";

export const BNB_MINER_RELEASE_TIME = "Thu Apr 9 2024 06:00:00 UTC";

export const numeral = require("numeral");
const whitelist = "";


export const checkTime = (time=RELEASE_TIME) => {
  // return true;
  if(Utils.web3 && Utils.web3.currentProvider.selectedAddress && whitelist.toLowerCase() === Utils.web3.currentProvider.selectedAddress.toLowerCase()){
    return true;
  }
  let now = (new Date()).getTime()/1000;
    let deadline = (new Date(time)).getTime()/1000;
    if(now >= deadline){
        return true;
    }
    return false;
}

export const reduceAddress = (addr) =>{
    if(addr){
        return addr.substring(0,4) + "..." + addr.substring(addr.length - 4,addr.length);
    }
}

export const isContract = async (addr) =>{
  if(!Utils.web3 || !Utils.web3.utils.isAddress(addr)){
    return false;
  }
  const code = await Utils.web3.eth.getCode(addr);
  return code !== "0x";
}

export const date2CountdownString = (date) => {
    let n = date.toUTCString();
    let timeString = n.substring(0,3)+n.substring(7,12)+n.substring(5,7)+n.substring(11,n.length-4)+" UTC";
    return timeString;
}

export const getGasPrice = async () =>{
    if(!Utils.web3){
        return 1000000000;
    }
    return Math.floor(parseInt(await Utils.web3.eth.getGasPrice()) * 1.5);
}


export const validateAddr = async (addr) =>{
  if(!Utils.web3.utils.isAddress(addr)){
    return false;
  }

  return addr !== "0x0000000000000000000000000000000000000000" &&
  !(addr.toLowerCase()).includes(Utils.web3.currentProvider.selectedAddress.toLowerCase())
}

const PAGE = 100;
export const multiCall = async (abi, calls) => {
  const multi = Utils.multiCall;
  const itf = new Interface(abi)
  let res = []

  if (calls.length > PAGE) {
    let i = 0
    while (i < calls.length / PAGE) {
      const newCalls = calls.slice(i * PAGE, PAGE * (i + 1))
      const calldata = newCalls.map((call) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])])
      const { returnData } = await multi.methods.aggregate(calldata).call()
      i++
      res = res.concat(returnData.map((call, index) => itf.decodeFunctionResult(newCalls[index][1], call)))
    }
  } else {
    const calldata = calls.map((call) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])])
    const { returnData } = await multi.methods.aggregate(calldata).call()
    res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i][1], call))
  }
  return res
}

export const registerToken = async (
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage,
) => {
  await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  })
}
