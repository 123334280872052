import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import EthMiner from "./views/EthMiner/EthMiner";
import {
  Utils,
  date2CountdownString,
} from './utils/utils';
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nativeBalance: 0,
      contractBalance: 0,
      barrelFullTime: 'Aug 09 2000 14:00:00 UTC',
      // languageFile: require('./assets/languages/en.json'),
      swapState:{
        tokenPrice:0,
        totalPlayer: 0,
        marketcap: 0,
        totalSupply: 0,
        tokenValue:0,
        marketcapValue:0,
        treasury: 0,
      }
    }
  }
  async componentDidMount() {
    this.checkContract()
    this.timer = setInterval(()=>{this.checkContract()}, 1000);
  }

  componentDidUpdate = (prevProps) =>{
    if(prevProps.address !== this.props.address){
      this.checkContract()
    }
  }

  checkContract=()=>{
    if(Utils.web3 &&
      this.props.address){
      clearInterval(this.timer);
      this.getContractStats();
      // this.getSwapData();
    }
  }

  getContractStats= async ()=>{
    clearTimeout(this.timeout)


    if(Utils.slerfMiner){
      try{
        const nativeBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(this.props.address))
        const contractBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(Utils.slerfMiner._address))
        const eggsPerMiner = await Utils.slerfMiner.methods.EGGS_TO_HATCH_1MINERS().call();
        const myMiners = await Utils.slerfMiner.methods.hatcheryMiners(this.props.address).call();
        const myEggs = await Utils.slerfMiner.methods.getMyEggs().call({from:this.props.address});
        let myEarns = 0;
        let hireEstimates = 0;
        let bnbPerHour = 0;
        let barrelFullTime = 'Aug 09 2000 14:00:00 UTC';
        if(myEggs > 0){
          myEarns = Utils.web3.utils.fromWei(await Utils.slerfMiner.methods.calculateEggSell(myEggs).call());
          const estimateEggs = await Utils.slerfMiner.methods.calculateEggBuySimple(Utils.web3.utils.toWei('0.1')).call();
          hireEstimates = estimateEggs/eggsPerMiner;
          const lastHatchTime = await Utils.slerfMiner.methods.lastHatch(this.props.address).call();

          if(contractBalance*1 > 0){
            const curTime = new Date().getTime();
            bnbPerHour = myEarns/(curTime/ 1000 - lastHatchTime) * 3600;
          }

          if(Number(bnbPerHour) < 0.000001){
            bnbPerHour = 0;
          }
          if(Number(myEarns) < 0.000001){
            myEarns = 0;
          }

          barrelFullTime = date2CountdownString(new Date((parseInt(lastHatchTime) + 86400) * 1000));

        }

        this.setState({
          nativeBalance:nativeBalance,
          contractBalanceEth:contractBalance,
          myMinersEth: myMiners,
          myEarnsEth: myEarns,
          hireEstimatesEth: hireEstimates,
          bnbPerHourEth: bnbPerHour,
          barrelFullTimeEth:barrelFullTime,
          ethBalance: nativeBalance,
        })
      }catch(e){
        console.log(e);
      }
    }

    this.timeout = setTimeout(()=>{
      this.getContractStats();
    }, 3000)
  }
  


  render(){
    return(

        <Switch>
          <Route exact path="/" render={
            ()=>(
              <EthMiner
                address={this.props.address}
                ethBalance={this.state.ethBalance}
                nativeBalance={this.state.nativeBalance}
                myMiners={this.state.myMinersEth}
                myEarns={this.state.myEarnsEth}
                hireEstimates={this.state.hireEstimatesEth}
                bnbPerHour={this.state.bnbPerHourEth}
                contractBalance={this.state.contractBalanceEth}
                barrelFullTime={this.state.barrelFullTimeEth}
                disconnect={this.props.disconnect}
                reconnect={this.props.reconnect}
                readWeb3Instance={this.props.readWeb3Instance}
              />
            )
          }/>

          <Route render={
            ()=>(
              <EthMiner
                address={this.props.address}
                ethBalance={this.state.ethBalance}
                nativeBalance={this.state.nativeBalance}
                myMiners={this.state.myMinersEth}
                myEarns={this.state.myEarnsEth}
                hireEstimates={this.state.hireEstimatesEth}
                bnbPerHour={this.state.bnbPerHourEth}
                contractBalance={this.state.contractBalanceEth}
                barrelFullTime={this.state.barrelFullTimeEth}
                disconnect={this.props.disconnect}
                reconnect={this.props.reconnect}
                readWeb3Instance={this.props.readWeb3Instance}
              />
            )
          }/>
        </Switch>

    )
  }
}

export default Routes;
