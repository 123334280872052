export const CHAINs = {
  'undefined':{
    id:56,
    name:'BSC',
    nativeToken:"ETH",
    swap:[
      {
        name: 'PancakeSwap',
        swapLink:'https://pancakeswap.finance/swap',
        addLiquidityLink:'https://pancakeswap.finance/add',
      },
    ]

  },
  '56':{
    id:56,
    name:'BSC',
    nativeToken:"ETH",
    swap:[
      {
        name: 'PancakeSwap',
        swapLink:'https://pancakeswap.finance/swap',
        addLiquidityLink:'https://pancakeswap.finance/add',
      },
    ]
  },

  '97':{
    id:97,
    name:'BSC',
    nativeToken:"ETH",
    swap:[
      {
        name: 'PancakeSwap',
        swapLink:'https://pancakeswap.finance/swap',
        addLiquidityLink:'https://pancakeswap.finance/add',
      },
    ]
  }
}

export const getChainName = (id) =>{
  return CHAINs[id] === undefined ? 'Network Error' : CHAINs[id].name;
}


export async function addNetwork(id) {

  let networkData;

  switch (id) {

    //bsctestnet

    case 66:

      networkData = [

        {

          chainId: '0x42',

          chainName: "OKExChain Mainnet",

          rpcUrls: ["https://exchainrpc.okex.org"],

          nativeCurrency: {

            name: "OKT",

            symbol: "OKT",

            decimals: 18,

          },

          blockExplorerUrls: ["https://www.oklink.com/okexchain"],

        },

      ];

      break;

    //bscmainet

    case 56:

      networkData = [

        {

          chainId: "0x38",

          chainName: "BSCMAINET",

          rpcUrls: ["https://bsc-dataseed1.binance.org"],

          nativeCurrency: {

            name: "BINANCE COIN",

            symbol: "ETH",

            decimals: 18,

          },

          blockExplorerUrls: ["https://bscscan.com/"],

        },

      ];

      break;

      case 97:

      networkData = [

        {

          chainId: "0x61",

          chainName: "BSCTest",

          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],

          nativeCurrency: {

            name: "BINANCE COIN",

            symbol: "ETH",

            decimals: 18,

          },

          blockExplorerUrls: ["https://testnet.bscscan.com/"],

        },

      ];

      break;

      case 42161:

      networkData = [

        {

          chainId: "0xA4B1",

          chainName: "Arbitrum One",

          rpcUrls: ["https://arbitrum-mainnet.infura.io"],

          nativeCurrency: {

            name: "ETH",

            symbol: "ETH",

            decimals: 18,

          },

          blockExplorerUrls: ["https://explorer.arbitrum.io"],

        },

      ];

      break;

      case 421613:

      networkData = [

        {

          chainId: "0x66EED",

          chainName: "Nitro Goerli Rollup Testnet",

          rpcUrls: ["https://goerli-rollup.arbitrum.io/rpc"],

          nativeCurrency: {

            name: "ETH",

            symbol: "ETH",

            decimals: 18,

          },

          blockExplorerUrls: ["https://goerli.arbiscan.io"],

        },

      ];

      break;

    default:

      break;

  }

  // agregar red o cambiar red
  console.log(networkData)
  console.log(window.ethereum)

  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkData[0].chainId }],
    });
  } catch (switchError) {
    console.log("switchError",switchError)
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: networkData,
        });
      } catch (addError) {
        // handle "add" error
        console.log("addError",addError)
      }
    }
    // handle other "switch" errors
  }
  // return window.ethereum.request({
  //
  //   method: "wallet_addEthereumChain",
  //
  //   params: networkData,
  //
  // });

}


export async function connect() {
  if(!window.ethereum){
    console.log("metamask not installed");
    return;
  }
  window.ethereum.enable()

}
